import React from "react";
import '../styles/NavbarStyle.css';

const TopNavbarComp = () => {
	return (
        <section className="header_topmenu fixed-top">
            <div className="row">
                <div className="col-sm-9">
                    <ul className="header__topleftmenu">
                        <li className="">
                            <span>Faculty Members</span>
                        </li>
                        <li className="">
                            <span>Batches</span>
                        </li>
                        <li className="">
                            <span>Career</span>
                        </li>
                        <li className="">
                            <span>Contact No: +91-9971167015</span>
                        </li>
                    </ul>
                </div>
                <div className="col-sm-3">
                    <div className="header__toprightmenu">
                        <ul className="header__language">
                            <li className="menuitem login-btn">
                                <span>Login</span>
                            </li>
                            {/* <li data-drupal-language="hi" data-drupal-link-system-path="<front>" className="hi menuitem">
                                <span>हिन्दी</span>
                            </li>
                            <li data-drupal-language="en" data-drupal-link-system-path="<front>" className="en menuitem is-active">
                                <span>En</span>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
	);
};

export default TopNavbarComp;