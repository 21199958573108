import React from 'react';
import HeaderComp from './HeaderComp';
import FooterComp from './FooterComp';
import HomePage from '../pages/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/CommonStyle.css';

function LayoutComp() {
	return (
		<div className="container-fluid">
			<HeaderComp />
			<HomePage />
			<FooterComp />
		</div>
	);
}

export default LayoutComp;
