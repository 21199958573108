import React from 'react';
import '../styles/FooterStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocation, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLinkedin, faTwitter, faYoutube, faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import { Form, Button } from 'react-bootstrap';

//functional component
const FooterComp = () => {
	console.log(process.env)
	return (
		<footer className="footer-section">
			<div className="container">
				<div className="footer-cta pt-5 pb-5">
					<div className="row">
						<div className="col-xl-4 col-md-4 mb-30">
							<div className="single-cta">
								<FontAwesomeIcon icon={faLocation} />
								<div className="cta-text">
									<h4>Find us</h4>
									<span>Mayur Vihar Phase 1, East Delhi, India</span>
								</div>
							</div>
						</div>
						<div className="col-xl-4 col-md-4 mb-30">
							<div className="single-cta">
							<FontAwesomeIcon icon={faPhoneAlt} />
								<div className="cta-text">
									<h4>Call us</h4>
									<span>+91-9971167015</span>
								</div>
							</div>
						</div>
						<div className="col-xl-4 col-md-4 mb-30">
							<div className="single-cta">
								<FontAwesomeIcon icon={faEnvelope} />
								<div className="cta-text">
									<h4>Mail us</h4>
									<span>info.astudyhub@gmail.com</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-content pt-5 pb-5">
					<div className="row">
						<div className="col-xl-4 col-lg-4 mb-50">
							<div className="footer-widget">
								<div className="footer-logo">
									<h4 className='text-light'>AStudyHub<br /><small style={ {fontSize: '12px'} }>एक अध्ययन केंद्र सामाजिक कल्याण के लिए</small></h4>
								</div>
								<div className="footer-text">
									<p>AStudyHub is a organization which provides coaching & guidance to the students.</p>
								</div>
								<div className="footer-social-icon">
									<span>Follow us</span>
									<a href={process.env.REACT_APP_FB_LINK} target="_blank" rel='noreferror'>
										<FontAwesomeIcon icon={faFacebook} />
									</a>
									<a href={process.env.REACT_APP_TW_LINK} target="_blank" rel='noreferror'>
										<FontAwesomeIcon icon={faTwitter} />
									</a>
									<a href={process.env.REACT_APP_LD_LINK} target="_blank" rel='noreferror'>
										<FontAwesomeIcon icon={faLinkedin} />
									</a>
									<a href="#">
										<FontAwesomeIcon icon={faYoutube} />
									</a>
								</div>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-6 mb-30">
							<div className="footer-widget">
								<div className="footer-widget-heading">
									<h3>Useful Links</h3>
								</div>
								<ul>
									<li><a href="#">Home</a></li>
									<li><a href="#">AStudyHub @Media</a></li>
									<li><a href="#">Subjects</a></li>
									<li><a href="#">Classes</a></li>
									<li><a href="#">Centers List</a></li>
									<li><a href="#">Education System</a></li>
									<li><a href="#">Alumni</a></li>
									<li><a href="#">Students Corner</a></li>
									<li><a href="#">Share Feedback</a></li>
								</ul>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-6 mb-50">
							<div className="footer-widget">
								<div className="footer-widget-heading">
									<h3>Subscribe</h3>
								</div>
								<div className="footer-text mb-25">
									<p>Don’t miss to subscribe to our new feeds, kindly submit your email address below.</p>
								</div>
								<div className="subscribe-form">
									<Form action="#">
										<Form.Control type="text" placeholder="Email Address" />
											<Button><FontAwesomeIcon icon={faTelegramPlane} /></Button>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="copyright-area">
				<div className="container">
					<div className="row">
						<div className="col-xl-6 col-lg-6 text-center text-lg-left">
							<div className="copyright-text">
								<p>&copy; 2022 AStudyHub - All Rights Reserved</p>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
							<div className="footer-menu">
								<ul>
									<li><a href="#">Terms of Use</a></li>
									<li><a href="#">Privacy Policy</a></li>
									<li><a href="#">Sitemap</a></li>
									<li><a href="#">Disclaimer</a></li>
									<li><a href="#">FAQs</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default FooterComp;