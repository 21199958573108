import React from 'react';
import TopNavbarComp from './TopNavbarComp';
import MainNavbarComp from './MainNavbarComp';

//functional component
const HeaderComp = () => {
    return (
		<header>
  			<TopNavbarComp />
            <MainNavbarComp />
      	</header>
	);
};

export default HeaderComp;