import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import sliderImage1 from '../assets/banner_images/banner1.jpg';
import sliderImage2 from '../assets/banner_images/banner2.jpg';
import sliderImage3 from '../assets/banner_images/banner3.jpg';


const MainBannerComp = () => {
    return (
  		<section className="no-padding" style={{marginTop:"136px"}}>
  			<Carousel autoPlay showThumbs={false} infiniteLoop={true} interval={5000}>
                <div>
                    <img src={ sliderImage1 } alt="Here is no cast, community and religion, we all are one."/>
                </div>
                <div>
                    <img src={ sliderImage2 } alt="Education is not only key for success but it also teaches us how we can live better lives."/>
                </div>
                <div>
                    <img src={ sliderImage3 } alt="An organization with a vision of making a better future for students and making better india."/>
                </div>
            </Carousel>
        </section>
	);
};

export default MainBannerComp;