import React from "react";

const HLabel = (props) => {
    return (
        <section className="custom-slogan mt-10">
            <h4 className='text-center lable-slogan' style={{border:'2px solid #1c7049',borderRadius:'5px',textTransform:'capitalize',padding:'1px 1px'}}>{props.text}</h4>	
            <br/>
        </section>
    )
}

export default HLabel;
