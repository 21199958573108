import React from 'react';
import '../styles/HomeStyle.css';
import MainBannerComp from '../components/MainBannerComp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import SloganLabelComp from '../components/SloganLabelComp';
import HeadingComp from '../components/HeadingComp';
import ScholarshipStudent from '../assets/images/scholarship-student.png';

const HomePage = () => {
	return (
		<main className="main-container">
      		<MainBannerComp />
			<section className='about' id='about'>
				<HeadingComp title="About AStudyHub" />
				<div className='container'>
					<p>
						AStudyHub is an organization which provides coaching and guidance to the students. We are trying to provide not only good education for students but we also assure students how to be successful in their careers. We're mainly focused only for 9th to 12th class students so that we can make them more focused in their life to become successful.
						<br/><br/>
						We believe there is no caste, community and religion we all are one. especially in India we see there are still people divided based upon various terms such as caste, community, religion, etc so we are trying to eliminate these things by providing a scholarship basis upon the student's family's current financial status. So based upon this scholarship criteria we are trying to make a better place for education with healthy competition.
						<br/><br/>
						Our main AIM is to provide a good level of education to the students as per comfort of students with the help of unique scholarship criteria and providing more and more employment.
					</p>
					<SloganLabelComp text="Let's Make Better Place of Education for Students Together" />
				</div>
			</section>
			<hr/>
			<section className='why-astudyhub' id='why-astudyhub'>
				<HeadingComp title="Why AStudyHub" />
				<div className='container'>
					<ul>
						<li><FontAwesomeIcon icon={faCheck} /> &nbsp; Dedicated and Best in class Teachers for each subjects.</li>
						<li><FontAwesomeIcon icon={faCheck} /> &nbsp; 12th class each batch is 90 minutes long.</li>
						<li><FontAwesomeIcon icon={faCheck} /> &nbsp; Not only focused on Marks but mainly focused for Increase Knowledge of Students.</li>
						<li><FontAwesomeIcon icon={faCheck} /> &nbsp; Basic preparations for CUET, JEE and NEET exams are included.</li>
						<li><FontAwesomeIcon icon={faCheck} /> &nbsp; Regular Tests.</li>
						<li><FontAwesomeIcon icon={faCheck} /> &nbsp; Career Guidance.</li>
						<li><FontAwesomeIcon icon={faCheck} /> &nbsp; No gossip in the classroom.</li>
						<li><FontAwesomeIcon icon={faCheck} /> &nbsp; Students helpful scholarship criteria.</li>
						<li><FontAwesomeIcon icon={faCheck} /> &nbsp; Only a maximum of 36 students in a single batch can sit. So that teacher can easily communicate with each student.</li>
						<li><FontAwesomeIcon icon={faCheck} /> &nbsp; 2 free demo classes.</li>
					</ul>
					<SloganLabelComp text="In modern society there are lots of obstacles for students but we are here for you to how you can get away from these obstacles" />
				</div>
			</section>
			<hr/>
			<section className='scholarship-criteria' id='scholarship-criteria'>
				<HeadingComp title="Scholarship Criteria" />
				<div className='container'>
					<img src={ScholarshipStudent} className="rounded mx-auto d-block" style={{maxHeight:'240px',marginBottom:'10px',marginTop:'10px'}} />
					<ul>
						<li><FontAwesomeIcon icon={faCheck} /> &nbsp; Providing direct 50% of scholarship for single mother children's.</li>
						<li><FontAwesomeIcon icon={faCheck} /> &nbsp; Providing scholarship to the students basis upon the financial status of thier parents.</li>
		
					</ul>
					<marquee>To know more regarding scholarship criteria you can contact us directly at +91-9971167015.</marquee>
					<SloganLabelComp text="We at AStudyHub trying to do that each students can get good level of education and career guidance" />
				</div>
			</section>
		</main>
	);
}; 

export default HomePage;