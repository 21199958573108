import React from "react";
import { Navbar, Container, Nav, Form, FormControl, Button } from "react-bootstrap";
import Logo from '../assets/images/logo512.png';

const MainNavbarComp = () => {
    return ( 
        <Navbar bg="light" className="fixed-top mt-30" expand="lg">
            <Container fluid>
                <Navbar.Brand href="#">
                    <img className="nav-logo" src={Logo} alt="astudyhub organization official logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                        <Nav.Link href="#about">About</Nav.Link>
                        <Nav.Link href="#why-astudyhub">Why AStudyHub</Nav.Link>
                        <Nav.Link href="#scholarship-criteria">Scholarship Criteria</Nav.Link>
                        <Nav.Link >Event & Activities</Nav.Link>
                    </Nav>
                    <Form className="d-flex">
                        <FormControl
                            type="search"
                            placeholder="Search"
                            className="me-2"
                            aria-label="Search"
                        />
                        <Button variant="outline-success">Search</Button>
                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}


export default MainNavbarComp;