import LayoutComp from "./components/LayoutComp";
import { useState, useEffect } from 'react';

function App() {
  // The back to top button is hidden initially
  const [showGoTopButton, setGoTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if(window.pageYOffset > 500){
        setGoTopButton(true);
      }else{
        setGoTopButton(false);
      }
    })
  }, []);

  const scrollToTop = () => {
      window.scrollTo({
        top:0,
        behavior:"smooth"
      });
  };

  return (
    <div className="App">
      <LayoutComp />

      {showGoTopButton && (
          <button onClick={scrollToTop} className="back-to-top" title="&#8679; Go To Top">
            &#8679;
          </button>
      )}
    </div>
  );
}

export default App;
