import React from "react";

const Heading = (props) => {
    return (
        <h2 className='text-center mt-3'>
            <u>{props.title}</u>
        </h2>
    )
}

export default Heading;